import {
  PageHeader,
  Box,
  PageSkeleton,
  Link,
  Button,
  Text,
} from '@bounty/brands-design-system'
import { Page } from '@bounty/brands-design-system'
import { IndividualEmbedTogglerSwitch } from './IndividualEmbedToggler'
import { ProductSnippetPreview } from './ProductPlacement/ProductSnippetPreview'
import { ThankYouSnippetPreview } from './ThankYouPlacement/ThankYouSnippetPreview'
import { NotificationEmailPreview } from './NotificationEmailPlacement/NotificationEmailPlacementPreview'
import {
  UserExperience,
  UserExperienceButton,
  UserExperienceButtonContainer,
  UserExperienceHeading,
  UserExperienceInfoContainer,
  UserExperiencePreview,
  UserExperienceText,
} from './UserExperience'
import { SnippetOverviewDocument } from '../../generated/backendGraphql'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { useFeatureForStore } from '../../hooks/useFeatureForStore'
import { FeatureUnavailable } from '../../components/FeatureUnavailable'
import { LearnMoreLink } from '../../components/LearnMoreLink/LearnMoreLink'
import { InstallSnippetToggleButton } from './InstallSnippetToggleButton'

export type SnippetsOverviewProps = unknown

const ChainLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      d="M4.715 6.542a.75.75 0 011.06 1.06l-2.868 2.868a2.5 2.5 0 003.536 3.536l3.535-3.535
             a2.5 2.5 0 00-1.768-4.268.75.75 0 010-1.5 4 4 0 012.828 6.828l-3.535 3.535
             a4 4 0 01-5.657-5.657l2.868-2.868z"
    />
    <path
      d="M9.185 9.858a.75.75 0 01-1.06-1.06l2.868-2.868a2.5 2.5 0 00-3.536-3.536L3.922 5.928
             a2.5 2.5 0 001.768 4.268.75.75 0 010 1.5 4 4 0 01-2.828-6.828l3.535-3.535
             a4 4 0 015.657 5.657L9.185 9.858z"
    />
  </svg>
)

export const SnippetsOverview = () => {
  const { isAvailable } = useFeatureForStore('PRODUCT_PAGE_WIDGET')
  const { data, loading } = useQueryBackend(SnippetOverviewDocument)

  console.log('data', data.currentStore?.id)

  if (loading || !data) return <PageSkeleton />
  const {
    currentStore: { embeds },
  } = data

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.currentStore.id)
    // Optionally, you can show feedback here (toast, alert, etc.)
  }

  /**
   * Note these embeds will have the same css variables appending to the document so they'll conflict
   * causing some things not to work property. The only way to fix this without altering all the variables is
   * the shadow dom
   */
  const productEmbed = embeds.find((e) => e.code === 'PRODUCT') || {
    configuration: { meta: {} },
  }
  const thankYouEmbed = embeds.find((e) => e.code === 'THANKYOU') || {
    configuration: { meta: {} },
  }

  return (
    <Page
      name="Signup widgets"
      alignItems="center"
      data-testid="user-experiences-page"
    >
      <PageHeader
        title="Signup widgets"
        description={
          <Text as="span">
            Signup widgets are widgets you can install in various places on your
            website or in your Shopify transactional emails, incentivizing
            customers to sign up and post when their order arrives.{' '}
            <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/sections/11758004566939-User-Experiences" />
          </Text>
        }
      />
      <Box maxInlineSize="100%">
        <UserExperience maxInlineSize="100%">
          <UserExperienceInfoContainer style={{ width: '100%' }}>
            <UserExperienceHeading>App Settings</UserExperienceHeading>
            <UserExperienceText>
              You will need to place the Store ID into every thank you page app
              widget you add to your store.
            </UserExperienceText>
            <UserExperienceText
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                // Force single-line, no wrap:
                whiteSpace: 'nowrap',
                // Optional (if you want to ensure the text doesnt overflow its container)
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              Store ID:&nbsp;{data.currentStore.id}
              <Button
                onClick={copyToClipboard}
                accessibilityLabel="Copy Store ID"
                style={{
                  background: 'transparent',
                  border: 'none',
                  padding: 0,
                  marginLeft: '4px',
                  marginRight: '4px',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                <ChainLinkIcon />
              </Button>
            </UserExperienceText>
          </UserExperienceInfoContainer>
        </UserExperience>
      </Box>

      <Box>
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Thank You Page</UserExperienceHeading>
            <UserExperienceText>
              Our highest converting placement shown on the order status page
              post purchase. Please refer to the installation guide below for
              instructions on installing and configuring the widget in your
              store{' '}
              <Link
                isExternal
                event={{
                  eventName:
                    'User Experience Overview Installation Guide Clicked',
                  placement: 'THANKYOU',
                }}
                href="https://bountyapp.notion.site/Thank-You-Page-dca3224a2dd34ef88db0c4466d8bd904"
              >
                refer to our installation guide.
              </Link>
            </UserExperienceText>

            {/*<UserExperienceButtonContainer mb="5">
              <InstallSnippetToggleButton
                code="THANKYOU"
                prettyName="Thank You Page Widget"
                showToggle={true}
              />
              <UserExperienceButton
                as={Link}
                to="../thank-you/customize"
                variant="outline"
                ml="4"
                event={{
                  eventName: 'User Experience Overview Customize Clicked',
                  placement: 'THANKYOU',
                }}
              >
                Customize
              </UserExperienceButton>
            </UserExperienceButtonContainer>*/}
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <ThankYouSnippetPreview
              p={['2', '2', '2', '4']}
              configProps={thankYouEmbed.configuration?.meta}
            />
          </UserExperiencePreview>
        </UserExperience>
        {/*<UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Product Page</UserExperienceHeading>
            <UserExperienceText>
              Our highest visibility placement. Maximize the number of creators
              you activate, and help convert potential creators who haven’t yet
              decided to buy. Please refer to the installation guide below for
              instructions on installing and configuring the widget in your
              store
            </UserExperienceText>
            {{isAvailable ? (
              <>
                <Box>
                  <IndividualEmbedTogglerSwitch
                    prettyName="Product Page Widget"
                    code={'PRODUCT'}
                  />
                </Box>
                <UserExperienceButtonContainer>
                  <UserExperienceButton
                    event={{
                      eventName:
                        'User Experience Overview Installation Guide Clicked',
                      placement: 'PRODUCT',
                    }}
                    as={Link}
                    to="../product/install"
                  >
                    Installation Guide
                  </UserExperienceButton>
                  <UserExperienceButton
                    as={Link}
                    to="../product/customize"
                    variant="outline"
                    ml="4"
                    event={{
                      eventName: 'User Experience Overview Customize Clicked',
                      placement: 'PRODUCT',
                    }}
                  >
                    Customize
                  </UserExperienceButton>
                </UserExperienceButtonContainer>
              </>
            ) : (
              <FeatureUnavailable ctaText="Upgrade to use the product page placement" />
            )}
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <ProductSnippetPreview
              p={['2', '2', '2', '4']}
              configProps={productEmbed.configuration?.meta}
            />
          </UserExperiencePreview>
        </UserExperience>*/}
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Notification Emails</UserExperienceHeading>
            <UserExperienceText>
              Add Bounty to order confirmation, shipping confirmation, and other
              notifications emails. Great for converting customers that haven't
              signed up yet and reminding those that have to create a Bounty.
            </UserExperienceText>
            <UserExperienceButtonContainer>
              <UserExperienceButton
                as={Link}
                isExternal
                event={{
                  eventName:
                    'User Experience Overview Installation Guide Clicked',
                  placement: 'NOTIFICATIONEMAILS',
                }}
                href="https://bountyapp.notion.site/Shopify-Notification-Emails-98e71813104044eea15efc35124c2ea7"
              >
                Installation Guide
              </UserExperienceButton>
            </UserExperienceButtonContainer>
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <NotificationEmailPreview p={['2', '2', '2', '4']} />
          </UserExperiencePreview>
        </UserExperience>
      </Box>
    </Page>
  )
}
